var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "d-flex justify-center" }, [
    _c(
      "div",
      { staticClass: "d-flex justify-center" },
      [
        _c(
          "v-form",
          { ref: "form", staticClass: "form-size" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "6", md: "6" } },
                  [
                    _c("v-text-field", {
                      staticClass: "input-size",
                      attrs: {
                        label: "Nome do 1º proponente",
                        name: "nomeProponente1",
                        "hide-details": "auto",
                        rules: [
                          v => !!v || "Campo obrigatório não preenchido."
                        ],
                        id: "nomeProponente1",
                        type: "text",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.nomeProponente1,
                        callback: function($$v) {
                          _vm.nomeProponente1 = $$v
                        },
                        expression: "nomeProponente1"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "6", md: "6" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: _vm.masks.cpf,
                          expression: "masks.cpf"
                        }
                      ],
                      staticClass: "input-size",
                      attrs: {
                        name: "cpfProponente1",
                        rules: [
                          v => !!v || "Campo obrigatório não preenchido."
                        ],
                        "hide-details": "auto",
                        type: "text",
                        label: "CPF do 1º proponente",
                        id: "cpfProponente1",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.cpfProponente1,
                        callback: function($$v) {
                          _vm.cpfProponente1 = $$v
                        },
                        expression: "cpfProponente1"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "6", md: "6" } },
                  [
                    _c("v-text-field", {
                      staticClass: "input-size",
                      attrs: {
                        label: "Nome do 2º proponente",
                        name: "nomeProponente2",
                        "hide-details": "auto",
                        id: "nomeProponente2",
                        type: "text",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.nomeProponente2,
                        callback: function($$v) {
                          _vm.nomeProponente2 = $$v
                        },
                        expression: "nomeProponente2"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "6", md: "6" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: _vm.masks.cpf,
                          expression: "masks.cpf"
                        }
                      ],
                      staticClass: "input-size",
                      attrs: {
                        name: "cpfProponente2",
                        type: "text",
                        "hide-details": "auto",
                        label: "CPF do 2º proponente",
                        id: "cpfProponente2",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.cpfProponente2,
                        callback: function($$v) {
                          _vm.cpfProponente2 = $$v
                        },
                        expression: "cpfProponente2"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "3", md: "3" } },
                  [
                    _c("v-select", {
                      attrs: {
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        items: _vm.dadosSituacao,
                        "item-value": "value",
                        "item-text": "name",
                        label: "Situação da análise",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.situacaoAnalise,
                        callback: function($$v) {
                          _vm.situacaoAnalise = $$v
                        },
                        expression: "situacaoAnalise"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "3", md: "3" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "currency",
                          rawName: "v-currency",
                          value: _vm.currencyOptions,
                          expression: "currencyOptions"
                        }
                      ],
                      staticClass: "input-size",
                      attrs: {
                        label: "Valor do imóvel",
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        type: "text",
                        name: "valorIMovel",
                        id: "valorIMovel",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.valorIMovel,
                        callback: function($$v) {
                          _vm.valorIMovel = $$v
                        },
                        expression: "valorIMovel"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "3", md: "3" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "currency",
                          rawName: "v-currency",
                          value: _vm.currencyOptions,
                          expression: "currencyOptions"
                        }
                      ],
                      staticClass: "input-size",
                      attrs: {
                        label: "Valor do financiamento",
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        type: "text",
                        name: "valorFinanciamento",
                        id: "valorFinanciamento",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.valorFinanciamento,
                        callback: function($$v) {
                          _vm.valorFinanciamento = $$v
                        },
                        expression: "valorFinanciamento"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "3", md: "3" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "currency",
                          rawName: "v-currency",
                          value: _vm.currencyOptions,
                          expression: "currencyOptions"
                        }
                      ],
                      staticClass: "input-size",
                      attrs: {
                        label: "Subsídio",
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        type: "text",
                        name: "subsidio",
                        id: "subsidio",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.subsidio,
                        callback: function($$v) {
                          _vm.subsidio = $$v
                        },
                        expression: "subsidio"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "1", md: "2" } },
                  [
                    _c("v-select", {
                      attrs: {
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        items: _vm.dadosFgts,
                        "item-value": "value",
                        "item-text": "name",
                        label: "Possui direito a usar FGTS?",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.possuiFGTS,
                        callback: function($$v) {
                          _vm.possuiFGTS = $$v
                        },
                        expression: "possuiFGTS"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "2", md: "2" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "currency",
                          rawName: "v-currency",
                          value: _vm.currencyOptions,
                          expression: "currencyOptions"
                        }
                      ],
                      staticClass: "input-size",
                      attrs: {
                        label: "FGTS",
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        type: "text",
                        name: "fgts",
                        id: "fgts",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.fgts,
                        callback: function($$v) {
                          _vm.fgts = $$v
                        },
                        expression: "fgts"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "3", md: "3" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "currency",
                          rawName: "v-currency",
                          value: _vm.currencyOptions,
                          expression: "currencyOptions"
                        }
                      ],
                      staticClass: "input-size",
                      attrs: {
                        label: "Valor total da proposta",
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        type: "text",
                        name: "valorTotalProposta",
                        id: "valorTotalProposta",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.valorTotalProposta,
                        callback: function($$v) {
                          _vm.valorTotalProposta = $$v
                        },
                        expression: "valorTotalProposta"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "3", md: "2" } },
                  [
                    _c("v-text-field", {
                      staticClass: "input-size",
                      attrs: {
                        label: "Prazo (meses)",
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        type: "text",
                        name: "prazoMeses",
                        id: "prazoMeses",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.prazoMeses,
                        callback: function($$v) {
                          _vm.prazoMeses = $$v
                        },
                        expression: "prazoMeses"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "3", md: "3" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "currency",
                          rawName: "v-currency",
                          value: _vm.currencyOptions,
                          expression: "currencyOptions"
                        }
                      ],
                      staticClass: "input-size",
                      attrs: {
                        label: "Valor da taxa de juros",
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        type: "text",
                        name: "valorTaxaJuros",
                        id: "valorTaxaJuros",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.valorTaxaJuros,
                        callback: function($$v) {
                          _vm.valorTaxaJuros = $$v
                        },
                        expression: "valorTaxaJuros"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "3", md: "3" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "currency",
                          rawName: "v-currency",
                          value: _vm.currencyOptions,
                          expression: "currencyOptions"
                        }
                      ],
                      staticClass: "input-size",
                      attrs: {
                        label: "Prestação aprovada",
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        type: "text",
                        name: "valorPrestacao",
                        id: "valorPrestacao",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.valorPrestacao,
                        callback: function($$v) {
                          _vm.valorPrestacao = $$v
                        },
                        expression: "valorPrestacao"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "3", md: "3" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "currency",
                          rawName: "v-currency",
                          value: _vm.currencyOptions,
                          expression: "currencyOptions"
                        }
                      ],
                      staticClass: "input-size",
                      attrs: {
                        label: "Renda bruta familiar",
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        type: "text",
                        name: "rendaFamiliar",
                        id: "rendaFamiliar",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.rendaFamiliar,
                        callback: function($$v) {
                          _vm.rendaFamiliar = $$v
                        },
                        expression: "rendaFamiliar"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "3", md: "3" } },
                  [
                    _c("v-select", {
                      attrs: {
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        items: _vm.dadosRedutorTaxa,
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        "item-value": "value",
                        "item-text": "name",
                        label: "Possui direito ao redutor na taxa?",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.possuiDireitoRedutorTaxa,
                        callback: function($$v) {
                          _vm.possuiDireitoRedutorTaxa = $$v
                        },
                        expression: "possuiDireitoRedutorTaxa"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "3", md: "3" } },
                  [
                    _c("v-select", {
                      attrs: {
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        items: _vm.dadosEnquadramento,
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        "item-value": "value",
                        "item-text": "name",
                        label: "Enquadramento",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.enquadramento,
                        callback: function($$v) {
                          _vm.enquadramento = $$v
                        },
                        expression: "enquadramento"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "4", md: "3" } },
                  [
                    _c("v-select", {
                      attrs: {
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        items: _vm.dadosAmortizacao,
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        "item-value": "value",
                        "item-text": "name",
                        label: "Sistema de amortização",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.sistemaAmortizacao,
                        callback: function($$v) {
                          _vm.sistemaAmortizacao = $$v
                        },
                        expression: "sistemaAmortizacao"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "4", md: "3" } },
                  [
                    _c("v-select", {
                      attrs: {
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        items: _vm.dadosIndexador,
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        "item-value": "value",
                        "item-text": "name",
                        label: "Indexador",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.indexador,
                        callback: function($$v) {
                          _vm.indexador = $$v
                        },
                        expression: "indexador"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "4", md: "3" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "##/##/####",
                          expression: "'##/##/####'"
                        }
                      ],
                      staticClass: "input-size",
                      attrs: {
                        label: "Data da avaliação",
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        name: "dataAvaliacao",
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        id: "dataAvaliacao",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.dataAvaliacao,
                        callback: function($$v) {
                          _vm.dataAvaliacao = $$v
                        },
                        expression: "dataAvaliacao"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "4", md: "3" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "##/##/####",
                          expression: "'##/##/####'"
                        }
                      ],
                      staticClass: "input-size",
                      attrs: {
                        label: "Data de vencimento",
                        disabled:
                          _vm.situacaoAnalise === "Reprovado" ||
                          _vm.situacaoAnalise === "Pendência",
                        name: "dataVencimento",
                        rules: [
                          v =>
                            !!v ||
                            _vm.situacaoAnalise === "Reprovado" ||
                            "Campo obrigatório não preenchido."
                        ],
                        id: "dataVencimento",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.dataVencimento,
                        callback: function($$v) {
                          _vm.dataVencimento = $$v
                        },
                        expression: "dataVencimento"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "12" } },
                  [
                    _c("v-textarea", {
                      staticClass: "input-size",
                      attrs: {
                        label: "Observações ou pendências",
                        rules: _vm.inputRules,
                        name: "observacoesPendencias",
                        id: "observacoesPendencias",
                        type: "text",
                        outlined: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.observacoesPendencias,
                        callback: function($$v) {
                          _vm.observacoesPendencias = $$v
                        },
                        expression: "observacoesPendencias"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-btn", { staticClass: "mr-4", on: { click: _vm.salvar } }, [
              _vm._v("Salvar")
            ]),
            _c("v-btn", { staticClass: "mr-4", on: { click: _vm.cancelar } }, [
              _vm._v("Cancelar")
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }