<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="d-flex justify-center">
    <div class="d-flex justify-center">
      <v-form class="form-size" ref="form">
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field label="Nome do 1º proponente" name="nomeProponente1" hide-details="auto"
              :rules="[v => !!v || 'Campo obrigatório não preenchido.']" v-model="nomeProponente1" id="nomeProponente1"
              type="text" outlined dense class="input-size">
            </v-text-field>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field name="cpfProponente1" :rules="[v => !!v || 'Campo obrigatório não preenchido.']"
              hide-details="auto" type="text" v-mask="masks.cpf" label="CPF do 1º proponente" v-model="cpfProponente1"
              id="cpfProponente1" outlined dense class="input-size">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" md="6">
            <v-text-field label="Nome do 2º proponente" name="nomeProponente2" hide-details="auto"
              v-model="nomeProponente2" id="nomeProponente2" type="text" outlined dense class="input-size">
            </v-text-field>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field name="cpfProponente2" type="text" hide-details="auto" v-mask="masks.cpf"
              label="CPF do 2º proponente" v-model="cpfProponente2" id="cpfProponente2" outlined dense
              class="input-size">
            </v-text-field>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="3" md="3">
            <v-select v-model="situacaoAnalise"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']"
              :items="dadosSituacao" item-value="value" item-text="name" label="Situação da análise" outlined dense />
          </v-col>
          <v-col cols="3" md="3">
            <v-text-field label="Valor do imóvel"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'"
              v-currency="currencyOptions"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']" type="text"
              v-model.lazy="valorImovel" name="valorIMovel" v-model="valorIMovel" id="valorIMovel" outlined dense
              class="input-size">
            </v-text-field>
          </v-col>
          <v-col cols="3" md="3">
            <v-text-field label="Valor do financiamento"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'"
              v-currency="currencyOptions"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']" type="text"
              v-model.lazy="valorFinanciamento" name="valorFinanciamento" v-model="valorFinanciamento"
              id="valorFinanciamento" outlined dense class="input-size">
            </v-text-field>
          </v-col>
          <v-col cols="3" md="3">
            <v-text-field label="Subsídio"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'"
              v-currency="currencyOptions"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']" type="text"
              v-model.lazy="subsidio" name="subsidio" v-model="subsidio" id="subsidio" outlined dense
              class="input-size">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1" md="2">
            <v-select v-model="possuiFGTS"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']"
              :items="dadosFgts" item-value="value" item-text="name" label="Possui direito a usar FGTS?" outlined
              dense />
          </v-col>
          <v-col cols="2" md="2">
            <v-text-field label="FGTS" :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'"
              v-currency="currencyOptions"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']" type="text"
              v-model.lazy="fgts" name="fgts" v-model="fgts" id="fgts" outlined dense class="input-size">
            </v-text-field>
          </v-col>
          <v-col cols="3" md="3">
            <v-text-field label="Valor total da proposta"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']" type="text"
              v-currency="currencyOptions" v-model.lazy="valorTotalProposta" name="valorTotalProposta"
              v-model="valorTotalProposta" id="valorTotalProposta" outlined dense class="input-size">
            </v-text-field>
          </v-col>
          <v-col cols="3" md="2">
            <v-text-field label="Prazo (meses)"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']" type="text"
              v-model.lazy="prazoMeses" name="prazoMeses" v-model="prazoMeses" id="prazoMeses" outlined dense
              class="input-size">
            </v-text-field>
          </v-col>
          <v-col cols="3" md="3">
            <v-text-field label="Valor da taxa de juros"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']" type="text"
              v-currency="currencyOptions" v-model.lazy="valorTaxaJuros" name="valorTaxaJuros" v-model="valorTaxaJuros"
              id="valorTaxaJuros" outlined dense class="input-size">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3" md="3">
            <v-text-field label="Prestação aprovada"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']" type="text"
              v-currency="currencyOptions" v-model.lazy="valorPrestacao" name="valorPrestacao" v-model="valorPrestacao"
              id="valorPrestacao" outlined dense class="input-size">
            </v-text-field>
          </v-col>
          <v-col cols="3" md="3">
            <v-text-field label="Renda bruta familiar"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']" type="text"
              v-model.lazy="valorImovel" name="rendaFamiliar" v-currency="currencyOptions" v-model="rendaFamiliar"
              id="rendaFamiliar" outlined dense class="input-size">
            </v-text-field>
          </v-col>
          <v-col cols="3" md="3">
            <v-select v-model="possuiDireitoRedutorTaxa"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'" :items="dadosRedutorTaxa"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']"
              item-value="value" item-text="name" label="Possui direito ao redutor na taxa?" outlined dense />
          </v-col>
          <v-col cols="3" md="3">
            <v-select v-model="enquadramento"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'" :items="dadosEnquadramento"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']"
              item-value="value" item-text="name" label="Enquadramento" outlined dense />
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="4" md="3">
            <v-select v-model="sistemaAmortizacao"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'" :items="dadosAmortizacao"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']"
              item-value="value" item-text="name" label="Sistema de amortização" outlined dense />
          </v-col>
          <v-col cols="4" md="3">
            <v-select v-model="indexador" :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'"
              :items="dadosIndexador"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']"
              item-value="value" item-text="name" label="Indexador" outlined dense />
          </v-col>
          <v-col cols="4" md="3">
            <v-text-field label="Data da avaliação"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'" name="dataAvaliacao"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']"
              v-model="dataAvaliacao" id="dataAvaliacao" v-mask="'##/##/####'" outlined dense class="input-size">
            </v-text-field>
          </v-col>
          <v-col cols="4" md="3">
            <v-text-field label="Data de vencimento"
              :disabled="situacaoAnalise === 'Reprovado' || situacaoAnalise === 'Pendência'" name="dataVencimento"
              :rules="[v => !!v || situacaoAnalise === 'Reprovado' || 'Campo obrigatório não preenchido.']"
              v-model="dataVencimento" id="dataVencimento" v-mask="'##/##/####'" outlined dense class="input-size">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>

          <v-col cols="12" md="12">
            <v-textarea label="Observações ou pendências" :rules="inputRules" name="observacoesPendencias"
              v-model="observacoesPendencias" id="observacoesPendencias" type="text" outlined dense class="input-size">
            </v-textarea>
          </v-col>
        </v-row>
        <v-btn @click="salvar" class="mr-4">Salvar</v-btn>
        <v-btn @click="cancelar" class="mr-4">Cancelar</v-btn>
      </v-form>
    </div>
  </v-container>
</template>
<script>
// noinspection ES6CheckImport
import _ from "lodash";
import { mapDadosRepostaAssinoFields } from "../../../store/modules/dados-resposta-assino";
import { mapRespostaAssinoFields } from "../../../store/modules/assino-resposta-assino";
import { mapActions, mapGetters } from "vuex";
import { OPTIONS } from "../../../config";
import ROUTERS from "../../../routes/router-constants";
export default {
  name: "RespostaAssino",

  data: () => ({
    masks: OPTIONS.masks,
    style: {
      colLeft: 3,
      colRight: 9,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    },
    currencyOptions: OPTIONS.currency,
    inputRules: [
      value => !!value || 'Campo obrigatório não preenchido.'
    ],
    rules: {
      select: [(value) => !!value || "Campo obrigatório não preenchido."]
    }
  }
  ),
  computed: {
    ...mapGetters("controller", ["operationType"]),
    ...mapDadosRepostaAssinoFields([
      "dadosSituacao",
      "dadosFgts",
      "dadosRedutorTaxa",
      "dadosEnquadramento",
      "dadosAmortizacao",
      "dadosIndexador"]),
    ...mapRespostaAssinoFields([
      "nomeProponente1",
      "cpfProponente1",
      "nomeProponente2",
      "cpfProponente2",
      "valorIMovel",
      "valorFinanciamento",
      "subsidio",
      "fgts",
      "prazoMeses",
      "valorTaxaJuros",
      "valorTotalProposta",
      "valorPrestacao",
      "rendaFamiliar",
      "dataAvaliacao",
      "dataVencimento",
      "observacoesPendencias",
      "enquadramento",
      "indexador",
      "possuiDireitoRedutorTaxa",
      "possuiFGTS",
      "sistemaAmortizacao",
      "situacaoAnalise"
    ])
  },
  methods: {
    ...mapActions("controller", [
      "menuSair"
    ]),
    ...mapActions("treinamento", [
      "resetCurrentTreinamento"
    ]),
    salvar() {
      if (this.$refs.form.validate()) {
        this.menuSair({ router: this.$router });
      }

    },
    cancelar() {
      this.resetCurrentTreinamento();
      this.$router.push({ name: ROUTERS.perfil.name });
    },
  },
  watch: {
    enquadramento: function (value) {
      if (!value) {
        // noinspection JSUnusedGlobalSymbols
        this.enquadramento = null;
      }
    },
    indexador: function (value) {
      if (!value) {
        // noinspection JSUnusedGlobalSymbols
        this.indexador = null;
      }
    },
    possuiDireitoRedutorTaxa: function (value) {
      if (!value) {
        // noinspection JSUnusedGlobalSymbols
        this.possuiDireitoRedutorTaxa = null;
      }
    },
    possuiFGTS: function (value) {
      if (!value) {
        // noinspection JSUnusedGlobalSymbols
        this.possuiFGTS = null;
      }
    },
    sistemaAmortizacao: function (value) {
      if (!value) {
        // noinspection JSUnusedGlobalSymbols
        this.sistemaAmortizacao = null;
      }
    },
    situacaoAnalise: function (value) {
      if (!value) {
        // noinspection JSUnusedGlobalSymbols
        this.situacaoAnalise = null;
      }
    }
  },
};
</script>


